#main-modal {
  width: 95vw !important;
  box-sizing: border-box;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.column-wrapper {
  // margin-left: 3rem;
  margin-bottom: 2rem;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  .section-wrapper {
    width: calc(50% - 10px);
  }

  @media screen and (max-width: 840px) {
    flex-direction: column;

    .section-wrapper {
      width: 100%;
    }
  }
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h3 {
    margin-left: 2rem;
    font-size: 24px;
  }
}

.section-wrapper {
  width: 100%;
  &:first-of-type {
    width: 100%;
  }
}

#pointer {
  font-size: 26px;
  background: #303030;
  padding: 0px 15px 0px 70px;
  // margin-left: -120px;
  margin-left: 0;
}

.poll-control {
  margin-left: 2rem !important;
}

.cnt {
  bottom: 2px;
  left: 3px;
  position: relative;
  color: white;
}

.paragraph {
  display: flex;
  align-items: center;

  &__triangle-right {
    width: 0;
    height: 0;

    border-top: 5px solid transparent;
    border-left: 10px solid black;
    border-bottom: 5px solid transparent;
    margin-right: 1rem;
  }

  &__title {
    font-weight: 700;
    margin: 0px 0 6px 0;
    font-size: 26px;
  }
}

.oevk-description {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 20px;
}

.party {
  &__wrapper {
    display: flex;
    font-size: 10pt;
    margin-bottom: 3px;
  }

  &__name {
    margin-right: 0.5rem;
    font-weight: 700;
  }
}

.description-gray {
  color: gray;
  font-size: 10pt;
}

.description {
  font-size: 13px;
  text-align: justify;
}

.description img {
  max-width: 100%;
}

.divider {
  max-width: 100%;
  height: 1px;
  background-color: lightgray;
  margin: 1rem 0;
}

.percentage-group {
  margin: 0.5rem 0;
  height: 12px;
  width: 60%;
  border-radius: 4px;
}

.share-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;

  .facebook-button,
  .twitter-button,
  .mail-button,
  .copy {
    height: 32px;
    display: flex;
    flex-direction: row;
    padding-right: 1rem !important;
    justify-content: flex-start;
    align-items: center;
    background-color: #303030 !important;
    color: white !important;

    &__share-icon {
      margin-right: 8px;
    }

    svg {
      width: 2em;
      height: 2em;
    }
  }

  .twitter-button {
    svg {
      width: 1em;
      height: 1em;
      margin-left: 0.5em;
    }
  }

  .copy {
    cursor: pointer;
    svg {
      width: 1.25em;
      height: 1.25em;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  .mail-button {
    text-decoration: none;
    svg {
      width: 1.25em;
      height: 1.25em;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  .download-file-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    background-color: #303030 !important;
    color: white;
    text-decoration: none;

    svg {
      width: 1em;
      height: 1em;
      margin-right: 12px;
    }
  }
}
