form {
  position: relative;
}

.accordion {
  margin-top: 1rem;
}

.title-control {
  margin-bottom: 2rem !important;
  width: 50%;
}

.deleteIcon {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

.addIcon {
  margin-top: 1rem !important;
}