.map-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
  width: calc(100vw - 10rem);
  left: 10rem;

  @media screen and (max-width: 840px) {
    height: calc(100vh - 5rem);
    width: 100vw;
    left: 0;
    top: 5rem;
  }
}

.search-result-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 6rem;
  left: 7rem;
  background-color: #F6F4F2;
  border-radius: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  width: 400px;

  @media screen and (max-width: 840px) {
    display: none;
  }

  .search-result-name {
    margin-bottom: 8px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .search-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background-color: white;
      border-radius: 1rem;
      cursor: pointer;
    }

    .search-result-description {
      font-style: italic;
      margin-left: 1rem;
    }
  }
}

.main-content {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.map {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

.dropdown-wrapper {
  position: absolute;
  top: 1.1rem;
  left: 50%;
  right: 50%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 840px) {
    display: none;
  }
}

.dropbtn {
  min-width: 16rem;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: "400";
  font-family: 'Toontime', sans-serif;
  color: #4c4c4c;
  background: white;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 50px;
  border-radius: 20px;
  border: 1px solid grey;
  box-shadow: 3px 3px 3px grey;

  &:hover {
    background: #aa8749;
    color: white;
  }
}

.dropdown-content {
  display: none;
  background-color: white;
  min-width: 160px;
  z-index: 1;
  border-radius: 20px;
  margin-top: 10px;
  padding: 12px 12px;
}

.dropdown-content span {
  text-decoration: none;
  border-radius: 6px;
  padding: 12px;
  display: block;
}

.dropdown-content span:hover {
  background-color: #f6f4f2;
  cursor: pointer;
}

.dropdown {
  margin-left: 8px;
}

.dropdown:first-child {
  margin-left: 0;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.map-id {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  font-size: 11px;
  padding: 0 5px;
  height: 16px;

  @media screen and (max-width: 840px) {
    bottom: 32px;
  }
}

.drawer-list {
  font-size: 0.8rem;
  color: grey;
  min-width: 260px;

  li {
    cursor: pointer;
    padding-top: 0.5rem;
    padding-left: 1.6rem;
    padding-bottom: 0.5rem;
  }

  &--title-wrapper {
    padding-left: 0.8rem;
    padding-top: 1rem;
  }

  &--title {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__sidebar-filter {
    padding-left: 0.8rem;

    &--wrapper {
      font-size: 0.8rem;
      color: grey;
      padding: 1rem 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &--wrapper:first-child {
      padding-top: 0;
    }

    &--element {
      cursor: pointer;
    }

    &--element > input {
      margin: 3px 3px 3px 0;
    }

    &--color-wrapper {
      padding: 0.8rem 0.8rem 0 0.8rem;
    }

    &--color {
      width: 100%;
      height: 12px;
      border-radius: 4px;
    }

    &--value-percentage {
      display: flex;
      justify-content: space-between;
      color: grey;
      font-size: 12px;
    }
  }
}

.sidebar {
  position: absolute;
  top: 15rem;
  left: 3rem;
  display: flex;
  text-align: left;
  background-color: white;
  // padding: 1rem;
  border-radius: 10px;
  flex-direction: column;
  color: #4c4c4c;
  border-radius: 1rem !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 30%);
  // border: 1px solid rgba(black, 20%);

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 8px 1rem;
    border-bottom: 2px solid #e8e6e4;
    background-color: #F6F4F2;

    &.first {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 20px;
      padding: 12px 16px
    }

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 4px 16px;
      color: black;
      background-color: #EFE9E1;
      border-radius: 1rem;
      cursor: pointer;
      width: 100%;

      
      &.selected {
        background-color: #AA8749;
        color: white;
      }
    }
  }

  .mapList {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .mapListItem {
    font-family: 'Toontime', sans-serif;
    text-align: center;
    margin: 6px 12px;
    padding: 10px 0px;
    border-radius: 0.5rem;
    cursor: pointer;
    
    &:hover {
      background: #f6f4f2;
    }
  }
  
  &__filter {
    input:hover {
      cursor: pointer;
    }

    &--wrapper {
      padding: 1rem 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 15px;
      font-weight: 400;
    }

    &--wrapper:first-child {
      padding-top: 1rem;
    }

    &--element {
      cursor: pointer;
    }

    &--element > input {
      margin: 3px 3px 3px 0;
    }
  }

  @media screen and (max-width: 840px) {
    display: none;
  }
}

.color-bar {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;
  background-color: white;
  padding: 1rem;
  // box-shadow: -1px 1px 24px 10px grey;
  border: 1px solid rgba(black, 10%);

  .color {
    width: 100%;
    height: 12px;
    border-radius: 4px;
  }

  .value-percentage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
}