.page-wrapper__details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  box-sizing: border-box;

  .column-wrapper {
    margin-left: 3rem;
    margin-bottom: 2rem;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h3 {
      margin-left: 2rem;
      font-size: 24px;
    }
  }

  .section-wrapper {
    width: 100%;
    &:first-of-type {
      width: 100%;
    }
  }

  #pointer {
    font-size: 26px;
    background: #303030;
    padding: 0px 15px 0px 70px;
    margin-left: 0px;
  }

  .cnt {
    bottom: 2px;
    left: 3px;
    position: relative;
    color: white;
  }

  .paragraph {
    display: flex;
    align-items: center;

    &__triangle-right {
      width: 0;
      height: 0;

      border-top: 5px solid transparent;
      border-left: 10px solid black;
      border-bottom: 5px solid transparent;
      margin-right: 1rem;
    }

    &__title {
      font-weight: 700;
      font-size: 26px;
      margin: 0;
    }
  }

  .oevk-description {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 20px;
  }

  .party {
    &__wrapper {
      display: flex;
      font-size: 10pt;
      margin-bottom: 3px;
    }

    &__name {
      margin-right: 0.5rem;
      font-weight: 700;
    }
  }

  .description-gray {
    color: gray;
    font-size: 10pt;
  }

  .description {
    font-size: 13px;
    text-align: justify;
  }

  .divider {
    max-width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 1rem 0;
  }

  .percentage-group {
    margin: 0.5rem 0;
    height: 12px;
    width: 60%;
    border-radius: 4px;
  }
}
