.header {
  width: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  background-color: #f6f4f2;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 30%);
  z-index: 1000;

  .filters {
    @media screen and (max-width: 840px) {
      display: none;
    }
  }

  @media screen and (max-width: 840px) {
    width: 100vw;
    height: 5rem;
    flex-direction: row;
    justify-content: space-around;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-items: center;
    width: 10rem;

    a {
      text-decoration: none !important;
    }

    @media screen and (max-width: 840px) {
      width: 100%;
      height: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .mobile-search {
        position: unset;
        width: 80% !important;
        margin-left: 10px;
      }
    }

    &--element-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      margin-bottom: 1rem;
      cursor: pointer;
      user-select: none;

      @media screen and (max-width: 840px) {
        width: auto;
        margin-bottom: 0;
        height: 100%;

        img {
          height: 100%;
          width: auto;
        }
      }

      svg {
        margin-bottom: 0.5rem;
      }

      &.cooperation,
      &.charts,
      &.primaries {
        @media screen and (max-width: 840px) {
          display: none;
        }
      }
    }

    &--element {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: auto;
      flex-direction: column;
      color: grey;
      text-decoration: none;
      border-radius: 1rem;
      padding: 1rem;
      box-sizing: border-box;

      &:hover {
        background-color: white;
      }
    }

    &--logo {
      object-fit: cover;
      height: auto;
      width: 140%;
      margin-left: 90px;
      margin-top: 20px;

      @media screen and (max-width: 840px) {
        width: 25%;
        margin-left: 0;
        margin-top: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &--login-wrapper {
      margin-left: auto;
    }

    &--button:hover {
      cursor: pointer;
      color: grey;
    }

    @media screen and (max-width: 840px) {
      &--element {
        min-width: 0;
      }

      &--text {
        display: none;
      }

      &--login-wrapper {
        display: none;
      }
    }

    &--social-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // margin-top: auto;
      margin-bottom: 1rem;
      width: 100%;

      @media screen and (max-width: 840px) {
        display: none;
      }
    }

    &--social,
    &--hamburger {
      color: grey;
      display: flex;
      margin-left: 1rem;

      @media screen and (max-width: 840px) {
        align-self: center;
      }
    }

    &--hamburger,
    &--social:last-child {
      margin-right: 1rem;
    }

    &--social:first-child {
      margin-left: 1rem;
    }

    @media screen and (min-width: 840px) {
      &--hamburger {
        display: none;
      }
    }
  }

  .impressum {
    margin-top: auto;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    color: grey;
    @media screen and (max-width: 840px) {
      display: none;
    }
  }
}
