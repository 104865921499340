input[type="file"] {
  display: none;
}

.download-file {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__download-button {
    background-color: grey;
    color: white;
    border: 1px solid grey;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    margin: 1rem;
    cursor: pointer;
  }

  &__select-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__action-container {
    display: flex;
    align-items: center;

    &--file-name {
      padding: 1rem;
      color: grey;
    }
  }

  &__text {
    @media screen and (max-width: 840px) {
      display: none;
    }
  }
}
