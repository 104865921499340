.MuiAutocomplete-root {
  position: absolute;
  left: 17rem;
  top: 1rem;
  background-color: white;
  border-radius: 1rem !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 30%);
  transition: box-shadow;
}

.MuiFormControl-root {
  border-radius: 1rem !important;
}

.MuiInputBase-root {
  border-radius: 1rem !important;

  input {
    font-family: 'Toontime', sans-serif;
    padding: 6px 16px !important;

    &::placeholder {
      color: black !important;
    }
  }
}
