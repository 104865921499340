@font-face {
  font-family: 'Toontime';
  src: local('Toontime'), url(resources/font/toontime.ttf) format('ttf');
}

.app {
  font-family: 'Toontime', sans-serif;
  text-align: center;

  &-frame {
    display: flex;

    &__main {
      display: flex;
      flex-direction: row;
      width: 100%;

      .desktop-search {
        @media screen and (max-width: 840px) {
          display: none;
        }
      }

      .mobile-search {
        @media screen and (min-width: 840px) {
          display: none;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 2000 !important;
}

.ReactModal__Content {
  max-width: 95vw !important;
  width: 660px !important;

  @media screen and (max-width: 840px) {
    max-width: unset !important;
    width: 80vw !important;
    padding: 20px !important;
  }
}
