.impressum-container {
  position: absolute;
  top: 10rem;
  left: 12rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (max-width: 840px) {
    left: 1rem;
  }
}
